exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-articles-index-jsx": () => import("./../../../src/pages/articles/index.jsx" /* webpackChunkName: "component---src-pages-articles-index-jsx" */),
  "component---src-pages-articles-strapi-post-slug-jsx": () => import("./../../../src/pages/articles/{StrapiPost.slug}.jsx" /* webpackChunkName: "component---src-pages-articles-strapi-post-slug-jsx" */),
  "component---src-pages-designandproduct-index-jsx": () => import("./../../../src/pages/designandproduct/index.jsx" /* webpackChunkName: "component---src-pages-designandproduct-index-jsx" */),
  "component---src-pages-draft-index-jsx": () => import("./../../../src/pages/draft/index.jsx" /* webpackChunkName: "component---src-pages-draft-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-people-index-jsx": () => import("./../../../src/pages/people/index.jsx" /* webpackChunkName: "component---src-pages-people-index-jsx" */),
  "component---src-pages-search-result-index-jsx": () => import("./../../../src/pages/search_result/index.jsx" /* webpackChunkName: "component---src-pages-search-result-index-jsx" */),
  "component---src-pages-story-index-jsx": () => import("./../../../src/pages/story/index.jsx" /* webpackChunkName: "component---src-pages-story-index-jsx" */),
  "component---src-pages-tag-search-result-index-jsx": () => import("./../../../src/pages/tag_search_result/index.jsx" /* webpackChunkName: "component---src-pages-tag-search-result-index-jsx" */),
  "component---src-pages-tips-index-jsx": () => import("./../../../src/pages/tips/index.jsx" /* webpackChunkName: "component---src-pages-tips-index-jsx" */)
}

